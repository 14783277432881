import { useCallback, useEffect, useState, memo, useMemo } from 'react';
import Select, { type MultiValue, type ActionMeta } from 'react-select';
import AsyncSelect from 'react-select/async';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import { type HCPType, LeadStatus, type PayerAdminType, SearchType, utils as $enumUtils } from '@enums';
import * as $search from '@services/api/search';
import * as QK from '@consts/querykey';
import { cx } from '@utils';
import type { Members } from '$admin/interfaces/search';
import { Input } from '@/components/Input';
import selectStyles from './SelectStyles';
import styles from './style/Filters.css';

type CompanyProps = {
  value: Members.Filters.FieldValueEntity[];
  onChange: (value: MultiValue<Members.Filters.FieldValueEntity>, meta: ActionMeta<Members.Filters.FieldValueEntity>) => unknown;
};

export const FieldValueCompany = memo(({ value, onChange }: CompanyProps) => {
  const fetch = useCallback((text: string): Promise<Members.Filters.FieldValueEntity[]> => {
    return $search.getAutocompleteResults({
      type: SearchType.Company,
      text,
      ignoreList: (value ?? []).map(v => `${v.id}`),
    })
      .then(result => result.map(r => ({
        id: r.id,
        name: r.name,
      })));
  }, [value]);

  return (
    <div className={styles.fieldValue}>
      <AsyncSelect<Descriptor, true>
        className={styles.select}
        onChange={onChange}
        loadOptions={fetch}
        getOptionLabel={o => o.name}
        getOptionValue={o => `${o.id}`}
        value={value}
        isMulti
        isClearable
        placeholder='Search current institution...'
        noOptionsMessage={({ inputValue }) => inputValue?.length ? 'No results' : null}
        styles={selectStyles}
        components={{
          DropdownIndicator: null,
        }} />
    </div>
  );
});

export const FieldValueCompanyFormer = memo(({ value, onChange }: CompanyProps) => {
  const fetch = useCallback((text: string): Promise<Members.Filters.FieldValueEntity[]> => {
    return $search.getAutocompleteResults({
      type: SearchType.Company,
      text,
      ignoreList: (value ?? []).map(v => `${v.id}`),
    })
      .then(result => result.map(r => ({
        id: r.id,
        name: r.name,
      })));
  }, [value]);

  return (
    <div className={styles.fieldValue}>
      <AsyncSelect<Descriptor, true>
        className={styles.select}
        onChange={onChange}
        loadOptions={fetch}
        getOptionLabel={o => o.name}
        getOptionValue={o => `${o.id}`}
        value={value}
        isMulti
        isClearable
        placeholder='Search former institution...'
        noOptionsMessage={({ inputValue }) => inputValue?.length ? 'No results' : null}
        styles={selectStyles}
        components={{
          DropdownIndicator: null,
        }} />
    </div>
  );
});

type JobTitleProps = {
  value: Members.Filters.FieldValueEntity[];
  onChange: (value: MultiValue<Members.Filters.FieldValueEntity>, meta: ActionMeta<Members.Filters.FieldValueEntity>) => unknown;
};

export const FieldValueJobTitle = memo(({ value, onChange }: JobTitleProps) => {
  const fetch = useCallback((text: string): Promise<Members.Filters.FieldValueEntity[]> => {
    return $search.getAutocompleteResults({
      type: SearchType.Title,
      text,
      ignoreList: (value ?? []).map(v => `${v.id}`),
    })
      .then(result => result.map(r => ({
        id: r.id,
        name: r.name,
      })));
  }, [value]);

  return (
    <div className={styles.fieldValue}>
      <AsyncSelect<Descriptor, true>
        className={styles.select}
        onChange={onChange}
        loadOptions={fetch}
        getOptionLabel={o => o.name}
        getOptionValue={o => `${o.id}`}
        value={value}
        isMulti
        isClearable
        placeholder='Search title...'
        noOptionsMessage={({ inputValue }) => inputValue?.length ? 'No results' : null}
        styles={selectStyles}
        components={{
          DropdownIndicator: null,
        }} />
    </div>
  );
});

type SectorProps = {
  value: Members.Filters.FieldValueEntity[];
  onChange: (value: MultiValue<Members.Filters.FieldValueEntity>, meta: ActionMeta<Members.Filters.FieldValueEntity>) => unknown;
};

export const FieldValueSector = memo(({ value, onChange }: SectorProps) => {
  const [options, setOptions] = useState<Descriptor[]>([]);

  useEffect(() => {
    $search.fetchSectorFields()
      .then(result => result.items.map(r => ({
        id: r.id,
        name: r.name,
      })))
      .then(options => setOptions(options));
  }, []);

  return (
    <div className={styles.fieldValue}>
      <Select<Descriptor, true>
        className={styles.select}
        onChange={onChange}
        options={options}
        getOptionLabel={o => o.name}
        getOptionValue={o => `${o.id}`}
        value={value}
        isMulti
        isClearable
        placeholder='Search sector...'
        noOptionsMessage={({ inputValue }) => inputValue?.length ? 'No results' : null}
        styles={selectStyles} />
    </div>
  );
});

type IndustryProps = {
  value: Members.Filters.FieldValueEntity[];
  onChange: (value: MultiValue<Members.Filters.FieldValueEntity>, meta: ActionMeta<Members.Filters.FieldValueEntity>) => unknown;
};

export const FieldValueIndustry = memo(({ value, onChange }: IndustryProps) => {
  const fetch = useCallback((text: string): Promise<Members.Filters.FieldValueEntity[]> => {
    return $search.getAutocompleteResults({
      type: SearchType.Industry,
      text,
      ignoreList: (value ?? []).map(v => `${v.id}`),
    })
      .then(result => result.map(r => ({
        id: r.id,
        name: r.name,
      })));
  }, [value]);

  return (
    <div className={styles.fieldValue}>
      <AsyncSelect<Descriptor, true>
        className={styles.select}
        onChange={onChange}
        loadOptions={fetch}
        getOptionLabel={o => o.name}
        getOptionValue={o => `${o.id}`}
        value={value}
        isMulti
        isClearable
        placeholder='Search specialty...'
        noOptionsMessage={({ inputValue }) => inputValue?.length ? 'No results' : null}
        styles={selectStyles}
        components={{
          DropdownIndicator: null,
        }} />
    </div>
  );
});

type SubIndustryProps = {
  value: Members.Filters.FieldValueEntity[];
  onChange: (value: MultiValue<Members.Filters.FieldValueEntity>, meta: ActionMeta<Members.Filters.FieldValueEntity>) => unknown;
};

export const FieldValueSubIndustry = memo(({ value, onChange }: SubIndustryProps) => {
  const fetch = useCallback((text: string): Promise<Members.Filters.FieldValueEntity[]> => {
    return $search.getAutocompleteResults({
      type: SearchType.SubIndustry,
      text,
      ignoreList: (value ?? []).map(v => `${v.id}`),
    })
      .then(result => result.map(r => ({
        id: r.id,
        name: r.name,
      })));
  }, [value]);

  return (
    <div className={styles.fieldValue}>
      <AsyncSelect<Descriptor, true>
        className={styles.select}
        onChange={onChange}
        loadOptions={fetch}
        getOptionLabel={o => o.name}
        getOptionValue={o => `${o.id}`}
        value={value}
        isMulti
        isClearable
        placeholder='Search subspecialty...'
        noOptionsMessage={({ inputValue }) => inputValue?.length ? 'No results' : null}
        styles={selectStyles}
        components={{
          DropdownIndicator: null,
        }} />
    </div>
  );
});

type ProductProps = {
  value: Members.Filters.FieldValueEntity[];
  onChange: (value: MultiValue<Members.Filters.FieldValueEntity>, meta: ActionMeta<Members.Filters.FieldValueEntity>) => unknown;
};

export const FieldValueProduct = memo(({ value, onChange }: ProductProps) => {
  const fetch = useCallback((text: string): Promise<Members.Filters.FieldValueEntity[]> => {
    return $search.getAutocompleteResults({
      type: SearchType.Product,
      text,
      ignoreList: (value ?? []).map(v => `${v.id}`),
    })
      .then(result => result.map(r => ({
        id: r.id,
        name: r.name,
      })));
  }, [value]);

  return (
    <div className={styles.fieldValue}>
      <AsyncSelect<Descriptor, true>
        className={styles.select}
        onChange={onChange}
        loadOptions={fetch}
        getOptionLabel={o => o.name}
        getOptionValue={o => `${o.id}`}
        value={value}
        isMulti
        isClearable
        placeholder='Search product...'
        noOptionsMessage={({ inputValue }) => inputValue?.length ? 'No results' : null}
        styles={selectStyles}
        components={{
          DropdownIndicator: null,
        }} />
    </div>
  );
});

type KeywordProps = {
  value: string;
  onChange: (value: string) => unknown;
};

export const FieldValueKeyword = memo(({ value, onChange }: KeywordProps) => {
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  }, [onChange]);

  return (
    <div className={styles.fieldValue}>
      <Input
        placeholder="Text"
        value={value}
        onChange={handleChange} />
    </div>
  );
});

type RoleProps = {
  value: Members.Filters.FieldValueEntity[];
  onChange: (value: MultiValue<Members.Filters.FieldValueEntity>, meta: ActionMeta<Members.Filters.FieldValueEntity>) => unknown;
};

export const FieldValueRole = memo(({ value, onChange }: RoleProps) => {

  const options = useMemo(() => {
    return $enumUtils.ProfessionalRole.values().map(m => ({
      id: m,
      name: $enumUtils.ProfessionalRole.getName(m),
    }));
  }, []);

  return (
    <div className={styles.fieldValue}>
      <Select<Descriptor, true>
        className={styles.select}
        onChange={onChange}
        options={options}
        getOptionLabel={o => o.name}
        getOptionValue={o => `${o.id}`}
        value={value}
        isMulti
        isClearable
        placeholder='Search role...'
        noOptionsMessage={({ inputValue }) => inputValue?.length ? 'No results' : null}
        styles={selectStyles} />
    </div>
  );
});

type CountryProps = {
  value: Members.Filters.FieldValueText[];
  onChange: (value: MultiValue<Members.Filters.FieldValueText>, meta: ActionMeta<Members.Filters.FieldValueText>) => unknown;
};

export const FieldValueCountry = memo(({ value, onChange }: CountryProps) => {
  const query = useQuery(['fetch-countries'], () => {
    return $search.fetchCountries().then(c => c.items.map<Members.Filters.FieldValueText>(i => ({
      id: i.code,
      name: i.name,
    })));
  });

  return (
    <div className={styles.fieldValue}>
      <Select<Members.Filters.FieldValueText, true>
        className={styles.select}
        onChange={onChange}
        isLoading={query.isFetching}
        options={query.data ?? []}
        getOptionLabel={o => o.name}
        getOptionValue={o => `${o.id}`}
        value={value}
        isMulti
        isClearable
        placeholder='Search country...'
        noOptionsMessage={({ inputValue }) => inputValue?.length ? 'No results' : null}
        styles={selectStyles}
        components={{
          DropdownIndicator: null,
        }} />
    </div>
  );
});

type MarketResearchProps = {
  qualifier: Members.Filters.FieldQualifierMarketResearch;
  value: Members.Filters.FieldValueEntity[];
  onChange: (value: MultiValue<Members.Filters.FieldValueEntity>, meta: ActionMeta<Members.Filters.FieldValueEntity>) => unknown;
};

export const FieldValueMarketResearch = memo(({ qualifier, value, onChange }: MarketResearchProps) => {
  const participation = useQuery([
    QK.Search.Medical.Options.ResearchParticipation.Get,
  ], () => {
    return api.search.medical.fetchResearchParticipationOptions()
    .then(res => {
      return {
        ...res,
        options: res.options.map(({ value, ...x }) => ({
          ...x,
          name: value,
        })),
      };
    });
  }, {
    refetchOnWindowFocus: false,
  });

  const options = useMemo(() => {
    if (participation.data?.options?.length) {
      return participation.data?.options.filter(o => o.type === qualifier);
    } else {
      return [];
    }
  }, [
    participation.data,
    qualifier,
  ]);

  return (
    <div className={styles.fieldValue}>
      <Select<Descriptor, true>
        className={styles.select}
        onChange={onChange}
        isLoading={participation.isFetching}
        options={options}
        getOptionLabel={o => o.name}
        getOptionValue={o => `${o.id}`}
        value={value}
        isMulti
        isClearable
        placeholder='Search preference...'
        noOptionsMessage={({ inputValue }) => inputValue?.length ? 'No results' : null}
        styles={selectStyles}
        components={{
          DropdownIndicator: null,
        }} />
    </div>
  );
});

type PayerAdminTypeProps = {
  value: Members.Filters.FieldValueEntity[];
  onChange: (value: MultiValue<Members.Filters.FieldValueEntity>, meta: ActionMeta<Members.Filters.FieldValueEntity>) => unknown;
};

export const FieldValuePayerAdminType = memo(({ value, onChange }: PayerAdminTypeProps) => {
  function formatName(id: PayerAdminType) {
    if (id >= 1 && id <= 20) {
      return `(US) ${$enumUtils.PayerAdminType.getName(id)}`;
    } else {
      return $enumUtils.PayerAdminType.getName(id);
    }
  }

  const options = useMemo(() => {
    return $enumUtils.PayerAdminType.values().map(m => ({
      id: m,
      name: formatName(m),
    }));
  }, []);

  return (
    <div className={styles.fieldValue}>
      <Select<Descriptor, true>
        className={styles.select}
        onChange={onChange}
        options={options}
        getOptionLabel={o => o.name}
        getOptionValue={o => `${o.id}`}
        value={value}
        isMulti
        isClearable
        placeholder='Search Payer / Admin Type...'
        noOptionsMessage={({ inputValue }) => inputValue?.length ? 'No results' : null}
        styles={selectStyles} />
    </div>
  );
});


type HCPTypeProps = {
  value: Members.Filters.FieldValueEntity[];
  onChange: (value: MultiValue<Members.Filters.FieldValueEntity>, meta: ActionMeta<Members.Filters.FieldValueEntity>) => unknown;
};

export const FieldValueHCPType = memo(({ value, onChange }: HCPTypeProps) => {
  const options = useMemo(() => {
    return $enumUtils.HCPType.values().map(m => ({
      id: m,
      name: $enumUtils.HCPType.getName(m),
    }));
  }, []);

  return (
    <div className={styles.fieldValue}>
      <Select<Descriptor, true>
        className={styles.select}
        onChange={onChange}
        options={options}
        getOptionLabel={o => o.name}
        getOptionValue={o => `${o.id}`}
        value={value}
        isMulti
        isClearable
        placeholder='Search HCP Type...'
        noOptionsMessage={({ inputValue }) => inputValue?.length ? 'No results' : null}
        styles={selectStyles} />
    </div>
  );
});

type CoveredLivesProps = {
  value: string;
  onChange: (value: string) => unknown;
};

export const FieldValueCoveredLives = memo(({ value, onChange }: CoveredLivesProps) => {
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  }, [onChange]);

  return (
    <div className={cx(styles.fieldValue, styles.covered)}>
      <Input
        placeholder="Amount"
        value={value}
        type="number"
        onChange={handleChange} />
    </div>
  );
});

type LeadStatusProps = {
  value: Members.Filters.FieldValueEntity[];
  onChange: (value: MultiValue<Members.Filters.FieldValueEntity>, meta: ActionMeta<Members.Filters.FieldValueEntity>) => unknown;
};

export const FieldValueLeadStatus = memo(({ value, onChange }: LeadStatusProps) => {
  const options = useMemo(() => {
    return [
      LeadStatus.Active,
      LeadStatus.Inactive,
      LeadStatus.Retired,
      LeadStatus.DoNotContact,
    ].map(x => ({
      id: x,
      name: $enumUtils.LeadStatus.getName(x),
    }));
  }, []);

  return (
    <div className={styles.fieldValue}>
      <Select<Descriptor, true>
        className={styles.select}
        onChange={onChange}
        options={options}
        getOptionLabel={o => o.name}
        getOptionValue={o => `${o.id}`}
        value={value}
        isMulti
        isClearable
        placeholder='Search role...'
        noOptionsMessage={({ inputValue }) => inputValue?.length ? 'No results' : null}
        styles={selectStyles} />
    </div>
  );
});

type BooleanProps = {
  value: Members.Filters.FieldValueBoolean[];
  onChange: (value: boolean) => unknown;
};

export const FieldValueBoolean = memo(({ value, onChange }: BooleanProps) => {
  const options = useMemo(() => {
    return [
      { id: true, name: 'True' },
      { id: false, name: 'False' },
    ];
  }, []);

  return (
    <div className={styles.fieldValue}>
      <Select<Members.Filters.FieldValueBoolean, false>
        className={styles.select}
        onChange={o => onChange(o.id)}
        options={options}
        getOptionLabel={o => o.name}
        getOptionValue={o => `${String(o.id)}`}
        value={value}
        isSearchable={false}
        styles={selectStyles} />
    </div>
  );
});