import { useCallback, useEffect } from 'react';
import type { IConference } from '@containers/Conference';
import { useMultiChat, useConferenceInstance, useLiveRoomBar, useLiveRoomScreen } from '@containers/Conference';
import { Left, Icons } from '@screens/ConferenceBar';
import { useYourParticipant } from './hooks';
import styles from './style/Bar.Left.css';

export function BarLeft() {
  const [bar, setBar] = useLiveRoomBar();

  const handleClick = useCallback((item: IConference.LiveRoom.Bar.Item) => {
    setBar(prev => ({
      ...prev,
      active: prev.active === item ? null : item,
    }));
  }, [setBar]);

  return (
    <Left className={styles.left}>
      <Information
        active={bar.active}
        onClick={handleClick} />
      <Participants
        active={bar.active}
        onClick={handleClick} />
      <Chat
        active={bar.active}
        onClick={handleClick} />
      <Support
        active={bar.active}
        onClick={handleClick} />
      <Surveys
        active={bar.active}
        onClick={handleClick} />
      <div className={styles.spacer} />
      <Logs
        active={bar.active}
        onClick={handleClick} />
    </Left>
  );
}

type ItemProps = {
  active: IConference.LiveRoom.Bar.Item;
  onClick: (item: IConference.LiveRoom.Bar.Item) => void;
};

function Information({ active, onClick }: ItemProps) {
  return (
    <Icons.Information
      className={styles.icon}
      active={active === 'information'}
      onClick={() => onClick('information')} />
  );
}

function Participants({ active, onClick }: ItemProps) {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const you = useYourParticipant();

  const count = you?.isHost
    ? instance.participants.filter(p => p.status === 'waiting-room' || p.status === 'meeting-room').length
    : instance.participants.filter(p => (p.id === you?.id || p.isVisible) && p.status === 'meeting-room').length;

  const alert = you?.isHost
    ? instance.participants.filter(p => p.status === 'waiting-room').length
    : 0;

  return (
    <Icons.Participants
      className={styles.icon}
      active={active === 'participants'}
      participants={count}
      onClick={() => onClick('participants')}
      alert={alert} />
  );
}

function Chat({ active, onClick }: ItemProps) {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const chat = useMultiChat();

  useEffect(() => {
    if (instance.features.chat) {
      chat.initialize(instance.conferenceIdentifier);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!instance.features.chat) return null;

  return (
    <Icons.Chat
      className={styles.icon}
      active={active === 'chat'}
      unread={chat.unread}
      onClick={() => onClick('chat')} />
  );
}

function Support({ active, onClick }: ItemProps) {
  const { isFullscreen } = useLiveRoomScreen();

  if (isFullscreen) return null;

  return (
    <Icons.Support
      className={styles.icon}
      active={active === 'support'}
      onClick={() => onClick('support')} />
  );
}

function Surveys({ active, onClick }: ItemProps) {
  const { isFullscreen } = useLiveRoomScreen();
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();

  if (isFullscreen) return null;

  if (!instance.features.surveys || !instance.surveys?.length) return null;

  return (
    <Icons.Surveys
      className={styles.icon}
      active={active === 'surveys'}
      onClick={() => onClick('surveys')} />
  );
}

function Logs({ active, onClick }: ItemProps) {
  const { isFullscreen } = useLiveRoomScreen();
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();

  if (isFullscreen) return null;

  return (
    <Icons.Logs
      className={styles.icon}
      active={active === 'logs'}
      onClick={() => onClick('logs')} />
  );
}