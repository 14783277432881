import { type ForwardedRef, forwardRef, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import { AutofillClientAccountContainer } from '@containers/Project.Creation';
import { useMethodologyOptions, useOverviewTooltipText } from '@containers/Project.Creation/hooks';
import { useHasSchedulingPermission } from '@containers/Store';
import { ProjectSubtype, ProjectType } from '@enums';
import { useProjectCreationNavigation, useProjectFormContext } from '@screens/ProjectCreation/context';
import { cx } from '@utils';
import * as Field from './Details.Field';
import { BackButton, Footer, NextButton } from './Footer';
import * as Layout from './Layout';
import styles from './style/Overview.css';

type Props = {
  features: Pick<Store.Group.Features,
    | 'projectTargetCompany'
    | 'repeatSurveys'
    | 'surveyProjects'>;
};

const DetailsWithRef = (props: Props, ref: ForwardedRef<HTMLInputElement>) => {
  const options = useMethodologyOptions(props.features.surveyProjects);
  const tooltip = useOverviewTooltipText(props.features.surveyProjects);
  const [form, setFormState] = useProjectFormContext();

  const actions = useProjectCreationNavigation();

  const showFrequency = useMemo(() => {
    const types = [
      ProjectType.Survey,
    ];

    return props.features.repeatSurveys
      && types.includes(form.projectType);
  }, [
    form.projectType,
    props.features.repeatSurveys,
  ]);

  const showProjectType = useMemo(() => {
    return form.projectType !== ProjectType.Survey;
  }, [form.projectType]);

  const disabled = useMemo(() => {
    const isSurvey = form.projectType === ProjectType.Survey;

    if (showProjectType && !form.projectSubtype) return true;

    if (!form.name.length) return true;

    return form.name.length <= 0
      || !isSurvey
      ? !validatePurchaseOrder(form.purchaseOrder)
      : !form.purchaseOrder
        ? false
        : !validatePurchaseOrder(form.purchaseOrder);
  }, [
    showProjectType,
    form.projectType,
    form.name,
    form.purchaseOrder,
    form.projectSubtype,
  ]);

  const permitted = useHasSchedulingPermission();

  const query = useQuery({
    queryKey: [`get:groups/search`],
    queryFn: api.groups.fetchAccounts,
    enabled: permitted,
  });

  const accounts = useMemo(() => {
    const items = query.data?.items ?? [];
    return {
      autofill: items.map(x => ({
        code: x.attributes?.code,
        id: x.id,
        name: x.name,
      })),
      options: items,
    };
  }, [query.data]);

  return (
    <Layout.Screen>
      <Layout.Header
        title={copy.title}
        subtitle={copy.subtitle} />

      <AutofillClientAccountContainer
        accountId={form.accountId}
        code={form.netsuite?.name ?? null}
        onAutofill={x => setFormState({ accountId: x?.id })}
        accounts={accounts.autofill}>
        {permitted &&
          <Field.Netsuite
            label={copy.label.netsuite}
            onChange={val => setFormState({ netsuite: val })}
            value={form.netsuite} />}

        <div className={styles.row}>
          <div className={cx(styles.half, styles.po)}>
            <Field.PurchaseOrder
              label={copy.label.purchaseOrder}
              onChange={val => setFormState({ purchaseOrder: val })}
              required={form.projectType !== ProjectType.Survey}
              value={form.purchaseOrder} />
          </div>

          {permitted &&
            <div className={cx(styles.half, styles.account)}>
              <Field.ClientAccount
                label="Client Account"
                onChange={val => setFormState({ accountId: val })}
                options={accounts.options}
                value={form.accountId} />
            </div>}
        </div>
      </AutofillClientAccountContainer>

      <Field.Name
        label={copy.label.name}
        onChange={e => setFormState({ name: e.target.value })}
        placeholder={copy.placeholder.name}
        ref={ref}
        tooltip={tooltip.title}
        value={form.name} />

      <Field.Name
        label={copy.label.clientName}
        onChange={e => setFormState({ clientName: e.target.value })}
        placeholder={copy.placeholder.clientName}
        tooltip={tooltip.clientTitle}
        value={form.clientName} />

      {props.features?.projectTargetCompany &&
        <Field.TargetCompany
          label={copy.label.targetCompany}
          onChange={value => setFormState({ targetCompany: value })}
          value={form.targetCompany} />}

      <div className={styles.inline}>
        <Field.Methodology
          defaultValue={options.find(x => x.id === form.projectType).name}
          label={copy.label.projectType}
          onChange={option => setFormState({ projectType: option.id, projectSubtype: option.id === ProjectType.Survey ? ProjectSubtype.Other : form.projectSubtype })}
          options={options}
          tooltip={tooltip.type} />

        <Field.Goal
          label={form.projectType === ProjectType.Survey ? copy.label.goal.survey : copy.label.goal.call}
          tooltip={tooltip.goal}
          onChange={(value: number) => setFormState({ goal: value })}
          projectType={form.projectType}
          value={form.goal} />
      </div>

      <div className={styles.inline}>
        <Field.TargetCompletionDate
          label={copy.label.targetCompleteDate}
          onChange={value => setFormState({ targetCompleteDate: value })}
          value={form.targetCompleteDate} />
        {showProjectType &&
          <Field.Subtype
            label={copy.label.projectSubType}
            required={true}
            tooltip={copy.tooltip.projectSubType}
            onChange={val => setFormState({ projectSubtype: val })}
            value={form.projectSubtype} />
        }
      </div>
      <div className={styles.inline}>
        {showFrequency &&
          <Field.Frequency
            label={copy.label.repeatFrequency}
            onChange={value => setFormState({ frequency: value })}
            tooltip={tooltip.frequency}
            value={form.frequency} />}
      </div>

      <Footer>
        <BackButton />
        <NextButton
          disabled={disabled}
          onClick={actions.next} />
      </Footer>
    </Layout.Screen>
  );
};

DetailsWithRef.displayName = 'Project.Creation.Frame.Details';

export const Details = forwardRef(DetailsWithRef);

const copy = {
  subtitle: `Enter some basic information about the project.`,
  title: `Overview`,
  label: {
    clientAccount: `Client Account`,
    clientName: `Client Title`,
    name: `Respondent Title`,
    purchaseOrder: `PMR Platform Fee Purchase Order`,
    goal: {
      call: `Completed Call Goal`,
      survey: `Total Responses Goal`,
    },
    netsuite: `Project Code`,
    projectSubType: `Project Type`,
    projectType: `Methodology`,
    repeatFrequency: `Repeat Frequency`,
    targetCompany: `Target Company`,
    targetCompleteDate: `Target Completion Date`,
  },
  placeholder: {
    clientName: `An optional title that clients will see`,
    name: `Enter the industry of your research`,
  },
  tooltip: {
    projectSubType: `This field captures the type of research that a team will be conducting and is used to pre-populate tags for the project.`,
  },
};

function validatePurchaseOrder(val: string) {
  const arr = (val || '').split('');
  const digits = arr.every(x => Number.isInteger(parseInt(x)));

  const valid = digits && arr.length === 5;

  return valid;
}