import { useCallback, useContext, useEffect, useMemo, forwardRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getLocationFor } from '@utils';
import { SurveyType } from '@enums';
import { Back } from '@presentation/Back';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { ProjectStateContainer } from '@containers/GroupProject/Project.State.Container';
import { useProjectState } from '@containers/GroupProject/hooks';
import { useMatchProjectIdFromUrl } from '@containers/GroupProject/hooks';
import { ProjectSurveyBuilderState, SurveyQuotasGoalContext, useIsEditingSurvey, useHasUnsavedChanges, useSurveyBuilderState, useSurveyTypesToExclude, useCanSaveSurvey } from '@containers/SurveyBuilder';
import { useFetchProjectGoal, useDiscardSurveyDraft } from '@utils/api';
import { DiscardDraftButton } from '@screens/ProjectCreation/Footer';
import { SurveyTemplateSelection } from '@screens/ProjectCreation/ProjectSurvey.Template';
import { ProjectSurveysContainer, useProjectSurveysContext } from '@/access/admin/containers/Project.Survey';
import type { RouteLeavingGuardState } from '@/components/RouteLeavingGuard';
import { RouteLeavingGuard } from '@/components/RouteLeavingGuard';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { Alert } from '@/components/Modal/Alert';
import { ProjectSurveyBuilder, useProjectSurveyBuilderTabs, DraftNotification } from '@/components/SurveyBuilder';
import { ProjectSurveyBuilderStepper } from '../ProjectCreation/ProjectSurvey.Stepper';
import { ProjectSurveyStepperContext } from '../ProjectCreation';
import { useSaveProjectSurvey } from './useSaveSurvey';
import styles from './style/ProjectSurveyEditor.css';

type LocationState = {
  from: string;
};

export const NewSurvey = () => {
  return (
    <ProjectStateContainer>
      <WithState />
    </ProjectStateContainer>
  );
};

const WithState = () => {
  const { projectId } = useMatchProjectIdFromUrl();
  const state = useProjectState();

  if (!state.project) {
    return <ActivityIndicator show />;
  }

  return (
    <ProjectSurveysContainer projectId={projectId}>
      <BuilderState>
        <SurveyBuilder />
      </BuilderState>
    </ProjectSurveysContainer>
  );
};

const BuilderState = (props: ChildrenProps) => {
  const state = useProjectState();
  const { query: surveysQuery } = useProjectSurveysContext();

  const excludeSurveyTypes = useSurveyTypesToExclude({
    surveys: surveysQuery.data.surveys,
    projectType: state.project.projectType,
  });
  return (
    <ProjectSurveyBuilderState
      excludeSurveyTypes={excludeSurveyTypes}
      projectType={state.project.projectType}>
      {props.children}
    </ProjectSurveyBuilderState>
  );
};

NewSurvey.displayName = 'Group.Project.Survey.New';

const SurveyBuilder = () => {
  const projectState = useProjectState();
  const history = useHistory();

  const handleComplete = useCallback((isExternal: boolean) => {
    if (isExternal) {
      history.push(getLocationFor.project.surveys({ slug: projectState.project.id.toString() }));
    }
  }, [history, projectState?.project?.id]);

  if (!projectState.project) {
    return <ActivityIndicator show />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <ProjectSurveyBuilderStepper
          SurveyBuilderScreen={SurveyBuilderScreen}
          SurveyTemplateScreen={SurveyTemplateScreen}
          project={projectState.project}
          onComplete={handleComplete} />
      </div>
    </div>
  );
};

const SurveyBuilderScreen = forwardRef(() => {
  const location = useLocation<LocationState>();
  const history = useHistory();
  const [state] = useSurveyBuilderState();
  const isEditing = useIsEditingSurvey();
  const hasUnsavedChanges = useHasUnsavedChanges();
  const [goalResponse, fetchGoal] = useFetchProjectGoal();

  const { projectId } = useMatchProjectIdFromUrl();

  const {
    isLoading: isSaving,
    mutateAsync: mutateSurvey,
  } = useSaveProjectSurvey(projectId);

  useEffect(() => {
    if (projectId) {
      fetchGoal(projectId);
    }
  }, [fetchGoal, projectId]);

  const backTo = useMemo(() => {
    return location.state?.from ||
      getLocationFor.project.surveyResponses({ slug: projectId.toString() });
  }, [projectId, location.state]);

  const saveSurvey = useCallback(() => {
    return mutateSurvey({
      survey: state.survey,
      draftVersionId: state.draft.surveyVersionId,
    }).then(() => {
      history.push(backTo);
    });
  }, [
    history,
    backTo,
    mutateSurvey,
    state.survey,
    state.draft.surveyVersionId,
  ]);

  const canSave = useCanSaveSurvey();

  const { mutate: discardDraft } = useDiscardSurveyDraft({});

  const tabs = useProjectSurveyBuilderTabs();

  if (!projectId) {
    return <ActivityIndicator show />;
  }

  return (
    <>
      <BackButton />
      <div className={styles.draftNotification}>
        <DraftNotification />
      </div>
      <SurveyQuotasGoalContext.Provider value={goalResponse.value?.goal?.value}>
        <ProjectSurveyBuilder tabs={tabs} />
      </SurveyQuotasGoalContext.Provider>
      <div className={styles.btns}>
        <DiscardDraftButton className={styles.save} />
        <ButtonActivityIndicator
          className={styles.save}
          onClick={saveSurvey}
          loading={isSaving}
          implicitDisable={isSaving}
          disabled={!canSave}>
          Save
        </ButtonActivityIndicator>
      </div>
      <RouteLeavingGuard block={hasUnsavedChanges()}>
        {(guard: RouteLeavingGuardState) => (
          <Alert
            confirmText="Save"
            cancelText='Discard'
            message="You have unsaved changes, do you want to save your draft?"
            onClose={() => { discardDraft({ surveyVersionId: state.draft.surveyVersionId }); guard.confirmNavigation(); }}
            onConfirm={guard.confirmNavigation}
            open={guard.open} />
        )}
      </RouteLeavingGuard>
    </>
  );

});

const SurveyTemplateScreen = () => {
  return (
    <>
      <BackButton />
      <SurveyTemplateSelection />
    </>
  );
};

const BackButton = () => {
  const ctx = useContext(ProjectSurveyStepperContext);

  return (
    <Back
      className={styles.back}
      onClick={ctx.back} />
  );
};

export default NewSurvey;