import extend from '@enums/extend';

export enum LeadStatus {
  Active       = 1,
  Inactive     = 2,
  Target       = 3,
  Retired      = 4,
  DoNotContact = 5,
}

const map = new Map([
  [LeadStatus.Active,       { name: 'Active' }],
  [LeadStatus.Inactive,     { name: 'Inactive' }],
  [LeadStatus.Target,       { name: 'Target' }],
  [LeadStatus.Retired,      { name: 'Retired' }],
  [LeadStatus.DoNotContact, { name: 'Do Not Contact' }],
]);

export default {
  LeadStatus: extend(map),
};