import { useCallback, useMemo, memo, Fragment } from 'react';
import type { Members as IMembers } from '$admin/interfaces/search';
import type { Members } from '../interfaces';
import { useFilters } from './hooks';
import { FiltersAddButton } from './Filters.Buttons';
import type {
  FieldQualifierCoveredLivesOption, FieldOperatorOptionBoolean, FieldOperatorOptionComparison, FieldQualifierNameOption,
  FieldQualifierPositionOption, FieldOperatorOptionMulti, FieldOperatorOptionMultiHybrid, FieldQualifierMarketResearchOption, FieldOperatorOptionText,
} from './Filter.Field';
import {
  FieldName, FieldValueCountry, FieldValueHCPType,
  FieldOperatorBoolean, FieldOperatorComparison,
  FieldOperatorText, FieldOperatorKeyword, FieldOperatorMulti, FieldOperatorMultiHybrid, FieldValueBoolean, FieldValueCompany,
  FieldQualifierCoveredLivesColumn, FieldQualifierCoveredLivesRow, FieldValueCoveredLives,
  FieldValueJobTitle, FieldValueKeyword, FieldValueLeadStatus, FieldQualifierPosition, FieldQualifierMarketResearch,
  FieldOperatorSingleCardMulti, FieldQualifierName, FieldValueSector, FieldValueMarketResearch, FieldValuePayerAdminType, FieldValueIndustry, FieldValueSubIndustry, FieldValueProduct, FieldValueRole,
} from './Filter.Field';

export function PendingFilter() {
  const [{ pending }, dispatch] = useFilters();

  const RenderField = useMemo(() => FilterMap[pending.field], [pending.field]);

  const canAdd = useMemo(() => !!pending?.value?.length, [pending?.value]);

  const handleFieldChange = useCallback(({ id }: Members.Filters.FieldNameOption) => {
    dispatch({
      type: 'update-pending-filter-field',
      field: id,
      fieldQual: FilterMap[id].defaultQualifier,
      operator: FilterMap[id].defaultOperator,
    });
  }, [dispatch]);

  const handleAdd = useCallback(() => {
    dispatch({
      type: 'add-pending-filter',
    });
  }, [dispatch]);

  // console.log('state.pending: ', pending);

  return (
    <Fragment>
      <FieldName
        value={pending.field}
        onChange={handleFieldChange} />
      <RenderField.Component
        pending={pending}
        dispatch={dispatch} />
      <FiltersAddButton
        disabled={!canAdd}
        onClick={handleAdd} />
    </Fragment>
  );
}

const Company = memo(({ pending, dispatch }: BaseFieldProps<'company'>) => {
  const handleQualifierChange = useCallback(({ id }: FieldQualifierPositionOption) => {
    dispatch({
      type: 'update-pending-filter-field-qualifier',
      qualifier: id,
      operator: FilterMap['company'].defaultOperator,
    });
  }, [dispatch]);

  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      type: 'update-pending-filter-field-operator',
      operator: id,
    });
  }, [dispatch]);

  const handleEntityValueChange = useCallback((value: IMembers.Filters.FieldValueEntity[]) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value,
    });
  }, [dispatch]);

  const handleKeywordValueChange = useCallback((value: string) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value: value?.length ? [{ id: value, name: value }] : [],
    });
  }, [dispatch]);

  return (
    <Fragment>
      <FieldQualifierPosition
        value={pending.fieldQual}
        onChange={handleQualifierChange} />
      {pending.fieldQual === 'current'
        ? (
          <FieldOperatorSingleCardMulti
            value={pending.fieldOp as IMembers.Filters.FieldOperatorsSingleCardMulti}
            onChange={handleOperatorChange} />
        )
        : (
          <FieldOperatorMultiHybrid
            value={pending.fieldOp}
            onChange={handleOperatorChange} />
        )}
      {pending.fieldOp !== 'c' &&
        <FieldValueCompany
          value={pending.value as IMembers.Filters.FieldValueEntity[]}
          onChange={handleEntityValueChange} />}
      {pending.fieldOp === 'c' &&
        <FieldValueKeyword
          value={pending.value.length ? pending.value[0].name : ''}
          onChange={handleKeywordValueChange} />}
    </Fragment>
  );
});

const CompanyFormer = memo(({ pending, dispatch }: BaseFieldProps<'company-former'>) => {
  const handleOperatorChange = useCallback(<T extends FieldOperatorOptionMultiHybrid>({ id }: T) => {
    dispatch({
      type: 'update-pending-filter-field-operator',
      operator: id,
    });
  }, [dispatch]);

  const handleEntityValueChange = useCallback((value: IMembers.Filters.FieldValueEntity[]) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value,
    });
  }, [dispatch]);

  const handleKeywordValueChange = useCallback((value: string) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value: value?.length ? [{ id: value, name: value }] : [],
    });
  }, [dispatch]);

  return (
    <Fragment>
      <FieldOperatorMultiHybrid
        value={pending.fieldOp}
        onChange={handleOperatorChange} />
      {pending.fieldOp !== 'c' &&
        <FieldValueCompany
          value={pending.value as IMembers.Filters.FieldValueEntity[]}
          onChange={handleEntityValueChange} />}
      {pending.fieldOp === 'c' &&
        <FieldValueKeyword
          value={pending.value.length ? pending.value[0].name : ''}
          onChange={handleKeywordValueChange} />}
    </Fragment>
  );
});

export const CoveredLives = memo(({ pending, dispatch }: BaseFieldProps<'covered-lives'>) => {
  const parsed = useMemo(() => {
    return {
      column: pending.fieldQual ? pending.fieldQual.split('_')[1] : '1',
      row: pending.fieldQual ? pending.fieldQual.split('_')[0] : '1',
    };
  }, [pending]);

  const handleQualifierChange = useCallback(({ id }: FieldQualifierCoveredLivesOption) => {
    const [cell, val] = id.split('_');
    const encoded = cell === 'col'
      ? `${parsed.row}_${val}`
      : `${val}_${parsed.column}`;

    dispatch({
      type: 'update-pending-filter-field-qualifier',
      qualifier: encoded,
      operator: FilterMap['covered-lives'].defaultOperator,
    });
  }, [
    dispatch,
    parsed,
  ]);

  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionComparison) => {
    dispatch({
      type: 'update-pending-filter-field-operator',
      operator: id,
    });
  }, [dispatch]);

  const handleValueChange = useCallback((value: string) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value: value?.length ? [{ id: value, name: value }] : [],
    });
  }, [dispatch]);

  return (
    <Fragment>
      <FieldQualifierCoveredLivesRow
        value={parsed.row}
        onChange={handleQualifierChange} />
      <FieldQualifierCoveredLivesColumn
        value={parsed.column}
        onChange={handleQualifierChange} />
      <FieldOperatorComparison
        value={pending.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueCoveredLives
        value={pending.value.length ? pending.value[0].name : ''}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const LeadStatus = memo(({ pending, dispatch }: BaseFieldProps<'lead-status'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      type: 'update-pending-filter-field-operator',
      operator: id,
    });
  }, [dispatch]);

  const handleValueChange = useCallback((value: IMembers.Filters.FieldValueEntity[]) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value,
    });
  }, [dispatch]);

  return (
    <Fragment>
      <FieldOperatorMulti
        value={pending.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueLeadStatus
        value={pending.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const Name = memo(({ pending, dispatch }: BaseFieldProps<'name'>) => {
  const handleQualifierChange = useCallback(({ id }: FieldQualifierNameOption) => {
    dispatch({
      type: 'update-pending-filter-field-qualifier',
      qualifier: id,
      operator: FilterMap['name'].defaultOperator,
    });
  }, [dispatch]);

  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionText) => {
    dispatch({
      type: 'update-pending-filter-field-operator',
      operator: id,
    });
  }, [dispatch]);

  const handleValueChange = useCallback((value: string) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value: value?.length ? [{ id: value, name: value }] : [],
    });
  }, [dispatch]);

  return (
    <Fragment>
      <FieldQualifierName
        value={pending.fieldQual}
        onChange={handleQualifierChange} />
      <FieldOperatorText
        value={pending.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueKeyword
        value={pending.value.length ? pending.value[0].name : ''}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const JobTitle = memo(({ pending, dispatch }: BaseFieldProps<'job-title'>) => {
  const handleQualifierChange = useCallback(({ id }: FieldQualifierPositionOption) => {
    dispatch({
      type: 'update-pending-filter-field-qualifier',
      qualifier: id,
      operator: FilterMap['job-title'].defaultOperator,
    });
  }, [dispatch]);

  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      type: 'update-pending-filter-field-operator',
      operator: id,
    });
  }, [dispatch]);

  const handleEntityValueChange = useCallback((value: IMembers.Filters.FieldValueEntity[]) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value,
    });
  }, [dispatch]);

  const handleKeywordValueChange = useCallback((value: string) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value: value?.length ? [{ id: value, name: value }] : [],
    });
  }, [dispatch]);

  return (
    <Fragment>
      <FieldQualifierPosition
        value={pending.fieldQual}
        onChange={handleQualifierChange} />
      <FieldOperatorMultiHybrid
        value={pending.fieldOp}
        onChange={handleOperatorChange} />
      {pending.fieldOp !== 'c' &&
        <FieldValueJobTitle
          value={pending.value as IMembers.Filters.FieldValueEntity[]}
          onChange={handleEntityValueChange} />}
      {pending.fieldOp === 'c' &&
        <FieldValueKeyword
          value={pending.value.length ? pending.value[0].name : ''}
          onChange={handleKeywordValueChange} />}
    </Fragment>
  );
});

const Sector = memo(({ pending, dispatch }: BaseFieldProps<'sector'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      type: 'update-pending-filter-field-operator',
      operator: id,
    });
  }, [dispatch]);

  const handleValueChange = useCallback((value: IMembers.Filters.FieldValueEntity[]) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value,
    });
  }, [dispatch]);

  return (
    <Fragment>
      <FieldOperatorMulti
        value={pending.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueSector
        value={pending.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const Industry = memo(({ pending, dispatch }: BaseFieldProps<'industry'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      type: 'update-pending-filter-field-operator',
      operator: id,
    });
  }, [dispatch]);

  const handleValueChange = useCallback((value: IMembers.Filters.FieldValueEntity[]) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value,
    });
  }, [dispatch]);

  return (
    <Fragment>
      <FieldOperatorMulti
        value={pending.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueIndustry
        value={pending.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const SubIndustry = memo(({ pending, dispatch }: BaseFieldProps<'sub-industry'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      type: 'update-pending-filter-field-operator',
      operator: id,
    });
  }, [dispatch]);

  const handleValueChange = useCallback((value: IMembers.Filters.FieldValueEntity[]) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value,
    });
  }, [dispatch]);

  return (
    <Fragment>
      <FieldOperatorMulti
        value={pending.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueSubIndustry
        value={pending.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const Product = memo(({ pending, dispatch }: BaseFieldProps<'product'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      type: 'update-pending-filter-field-operator',
      operator: id,
    });
  }, [dispatch]);

  const handleValueChange = useCallback((value: IMembers.Filters.FieldValueEntity[]) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value,
    });
  }, [dispatch]);

  return (
    <Fragment>
      <FieldOperatorMulti
        value={pending.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueProduct
        value={pending.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const ProjectCode = memo(({ pending, dispatch }: BaseFieldProps<'project-code'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionText) => {
    dispatch({
      type: 'update-pending-filter-field-operator',
      operator: id,
    });
  }, [dispatch]);

  const handleValueChange = useCallback((value: string) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value: value?.length ? [{ id: value, name: value }] : [],
    });
  }, [dispatch]);

  return (
    <Fragment>
      <FieldOperatorText
        value={pending.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueKeyword
        value={pending.value.length ? pending.value[0].name : ''}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const Keyword = memo(({ pending, dispatch }: BaseFieldProps<'keyword'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionText) => {
    dispatch({
      type: 'update-pending-filter-field-operator',
      operator: id,
    });
  }, [dispatch]);

  const handleValueChange = useCallback((value: string) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value: value?.length ? [{ id: value, name: value }] : [],
    });
  }, [dispatch]);

  return (
    <Fragment>
      <FieldOperatorKeyword
        value={pending.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueKeyword
        value={pending.value.length ? pending.value[0].name : ''}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const Role = memo(({ pending, dispatch }: BaseFieldProps<'role'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      type: 'update-pending-filter-field-operator',
      operator: id,
    });
  }, [dispatch]);

  const handleValueChange = useCallback((value: IMembers.Filters.FieldValueEntity[]) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value,
    });
  }, [dispatch]);

  return (
    <Fragment>
      <FieldOperatorMulti
        value={pending.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueRole
        value={pending.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const Country = memo(({ pending, dispatch }: BaseFieldProps<'country'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      type: 'update-pending-filter-field-operator',
      operator: id,
    });
  }, [dispatch]);

  const handleValueChange = useCallback((value: IMembers.Filters.FieldValueText[]) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value,
    });
  }, [dispatch]);

  return (
    <Fragment>
      <FieldOperatorMulti
        value={pending.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueCountry
        value={pending.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const MarketResearch = memo(({ pending, dispatch }: BaseFieldProps<'market-research'>) => {
  const handleQualifierChange = useCallback(({ id }: FieldQualifierMarketResearchOption) => {
    dispatch({
      type: 'update-pending-filter-field-qualifier',
      qualifier: id,
      operator: FilterMap['market-research'].defaultOperator,
    });
    dispatch({
      type: 'update-pending-filter-field-value',
      value: [],
    });
  }, [dispatch]);

  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      type: 'update-pending-filter-field-operator',
      operator: id,
    });
  }, [dispatch]);

  const handleValueChange = useCallback((value: IMembers.Filters.FieldValueEntity[]) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value,
    });
  }, [dispatch]);

  return (
    <Fragment>
      <FieldQualifierMarketResearch
        value={pending.fieldQual}
        onChange={handleQualifierChange} />
      <FieldOperatorSingleCardMulti
        value={pending.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueMarketResearch
        qualifier={pending.fieldQual}
        value={pending.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const PayerAdminType = memo(({ pending, dispatch }: BaseFieldProps<'payer-admin-type'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionMulti) => {
    dispatch({
      type: 'update-pending-filter-field-operator',
      operator: id,
    });
  }, [dispatch]);

  const handleValueChange = useCallback((value: IMembers.Filters.FieldValueEntity[]) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value,
    });
  }, [dispatch]);

  return (
    <Fragment>
      <FieldOperatorMulti
        value={pending.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValuePayerAdminType
        value={pending.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const HCPType = memo(({ pending, dispatch }: BaseFieldProps<'hcp-type'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionBoolean) => {
    dispatch({
      type: 'update-pending-filter-field-operator',
      operator: id,
    });
  }, [dispatch]);

  const handleValueChange = useCallback((value: IMembers.Filters.FieldValueEntity[]) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value,
    });
  }, [dispatch]);

  return (
    <Fragment>
      <FieldOperatorBoolean
        value={pending.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueHCPType
        value={pending.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

const IsPayer = memo(({ pending, dispatch }: BaseFieldProps<'is-payer'>) => {
  const handleOperatorChange = useCallback(({ id }: FieldOperatorOptionBoolean) => {
    dispatch({
      type: 'update-pending-filter-field-operator',
      operator: id,
    });
  }, [dispatch]);

  const handleValueChange = useCallback((value: boolean) => {
    dispatch({
      type: 'update-pending-filter-field-value',
      value: [{ id: value, name: value ? 'True' : 'False' }],
    });
  }, [dispatch]);

  return (
    <Fragment>
      <FieldOperatorBoolean
        value={pending.fieldOp}
        onChange={handleOperatorChange} />
      <FieldValueBoolean
        value={pending.value}
        onChange={handleValueChange} />
    </Fragment>
  );
});

type BaseFieldProps<F extends IMembers.Filters.Field> = {
  pending: IMembers.Filters.Item<F>;
  dispatch: React.Dispatch<Members.Filters.Action>;
};

type FilterMapValue = {
  Component: (props: BaseFieldProps<IMembers.Filters.Field>) => React.ReactNode;
  defaultQualifier: IMembers.Filters.FieldQualifier;
  defaultOperator: IMembers.Filters.FieldOperator;
};

const FilterMap: Record<IMembers.Filters.Field, FilterMapValue> = {
  'company': {
    Component: Company,
    defaultQualifier: 'any',
    defaultOperator: 'c',
  },
  'company-former': {
    Component: CompanyFormer,
    defaultQualifier: 'former',
    defaultOperator: 'c',
  },
  'covered-lives': {
    Component: CoveredLives,
    defaultQualifier: '1_1',
    defaultOperator: 'lt',
  },
  'lead-status': {
    Component: LeadStatus,
    defaultQualifier: null,
    defaultOperator: 'ioo',
  },
  'name': {
    Component: Name,
    defaultQualifier: 'full',
    defaultOperator: 'c',
  },
  'job-title': {
    Component: JobTitle,
    defaultQualifier: 'any',
    defaultOperator: 'c',
  },
  'sector': {
    Component: Sector,
    defaultQualifier: 'any',
    defaultOperator: 'ioo',
  },
  'industry': {
    Component: Industry,
    defaultQualifier: 'any',
    defaultOperator: 'ioo',
  },
  'sub-industry': {
    Component: SubIndustry,
    defaultQualifier: 'any',
    defaultOperator: 'ioo',
  },
  product: {
    Component: Product,
    defaultQualifier: 'any',
    defaultOperator: 'ioo',
  },
  keyword: {
    Component: Keyword,
    defaultQualifier: null,
    defaultOperator: 'c',
  },
  role: {
    Component: Role,
    defaultQualifier: 'any',
    defaultOperator: 'ioo',
  },
  'market-research': {
    Component: MarketResearch,
    defaultQualifier: 'in-person',
    defaultOperator: 'ioo',
  },
  'payer-admin-type': {
    Component: PayerAdminType,
    defaultQualifier: 'any',
    defaultOperator: 'ioo',
  },
  'is-payer': {
    Component: IsPayer,
    defaultQualifier: null,
    defaultOperator: 'i',
  },
  'hcp-type': {
    Component: HCPType,
    defaultQualifier: null,
    defaultOperator: 'i',
  },
  'project-code': {
    Component: ProjectCode,
    defaultQualifier: null,
    defaultOperator: 'c',
  },
  country: {
    Component: Country,
    defaultQualifier: 'any',
    defaultOperator: 'ioo',
  },
};