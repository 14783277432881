import BarChartOutlined from '@mui/icons-material/BarChartOutlined';
import { cx } from '@utils';
import { SupportIconProps } from './interfaces';
import styles from './style.css';

export const SurveysIcon = (props: SupportIconProps) => (
  <div
    className={cx(styles.icon, { [styles.active]: props.active }, props.className)}
    onClick={props.onClick}>
    <BarChartOutlined />
  </div>
);