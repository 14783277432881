import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectType } from '@enums';
import { ExternalSurveyConfigurationContext } from '@containers/Project.ExternalSurvey';
import { ProjectSurveySettingsContainer, ProjectSurveySettingsContext, useCanEditSurveySettings } from '@containers/Project.SurveySettings';
import { OffPlatformSourcing } from '@/access/admin/Project.Details/Section.Settings.Survey.OffPlatformSourcing';
import { OffPlatformSurvey } from '@/access/admin/Project.Details/Section.Settings.Survey.OffPlatformSurvey';
import { SettingsSurvey } from '@/access/admin/Project.Details/Section.Settings.Survey';
import { Honoraria } from '@/access/admin/Project.Details/Section.Honoraria';
import styles from './style/SurveySettings.css';

const ProjectSurveySettings = () => {

  const ctx = useContext(ProjectSurveySettingsContext);

  const externalConfiguration = useContext(ExternalSurveyConfigurationContext);

  const canEdit = useCanEditSurveySettings();

  return (
    <div className={styles.root}>
      {ctx.project.projectType === ProjectType.Survey && (
        <div>
          <SettingsSurvey />
        </div>
      )}
      {externalConfiguration && (
        <OffPlatformSurvey
          allowEditSection={canEdit}
          projectId={ctx.project.id} />
      )}
      {ctx.attributes.externalSourcing && <OffPlatformSourcing />}
      <Honoraria />
    </div>
  );
};

const Container = () => {

  const params = useParams<Stringify<IProjectId>>();
  const projectId = +params.projectId;

  return (
    <ProjectSurveySettingsContainer projectId={projectId}>
      <ProjectSurveySettings />
    </ProjectSurveySettingsContainer>
  );
};

export { Container as ProjectSurveySettings };
