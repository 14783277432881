import { path } from '@consts';
import { RouteAuth } from '@enums';
import { StateRouter } from '@routes/Routers';
import type * as Routing from '@routes/interfaces';
import { GroupProjectCloningContainer } from '@containers/GroupProject';
import { ProjectTestDriveContainer } from '@containers/ProjectOnboarding/ProjectTestDriveContainer';
import { SurveyVersionPreviewContainer } from '@containers/ProjectOnboarding/Survey.Version.Preview.Container';
import { FileNotFound } from '@screens/Workspace.File/';
import { GroupProject } from '@screens/GroupProject';
import { GroupProjects } from '@screens/Group.Projects';
import * as Member from '@screens/Projects.Member';
import { SurveyPreviewComplete } from '@screens/Survey.Preview';
import { ProjectDetails } from '@screens/ProjectDetails';
import { ProjectCreation } from '@screens/ProjectCreation';
import { EntitiesOnboarding } from '@/screens/Project.Entities/Onboarding';
import { ProjectSurveyEditor, ProjectSurveys, NewSurvey } from '@screens/ProjectSurveyEditor';
import { ProjectSurveyRespondent } from '@screens/ProjectSurveyRespondent';
import { ProjectTagsOnboardingContainer } from '@screens/Project.Tags.Onboarding';
import { CallTranscript } from '@screens/Call.Transcript';
import { ProjectFilePreview } from '@screens/Project.FilePreview';
import { ProjectSurveySettings } from '@screens/Project.SurveySettings';
import * as utils from '@utils';
import Search from '@/scenes/search/ProjectSearch';

const Client = {
  Creation: ProjectCreation,
  Projects: GroupProjects,
  Search,
  CallTranscript,
};

const Consultant = {
  Project: ProjectDetails,
  Projects: Member.Projects,
};

const client: Routing.Route[] = [
  {
    component: Client.Creation,
    path: path.Projects.New,
  },
  {
    component: Client.Projects,
    exact: true,
    path: path.Projects.Root,
  },
  {
    exact: true,
    component: Client.Search,
    path: path.Projects.Search,
  },
  {
    component: Client.CallTranscript,
    exact: true,
    path: path.Projects.Transcript,
  },
  {
    component: Client.CallTranscript,
    exact: true,
    path: path.Projects.TranscriptLegacy,
  },
  {
    component: ProjectSurveyRespondent,
    exact: true,
    path: path.Projects.SurveyResponse,
  },
  {
    component: NewSurvey,
    exact: true,
    path: path.Projects.SurveyNew,
  },
  {
    component: ProjectSurveyEditor,
    exact: true,
    path: path.Projects.SurveyEditor,
  },
  {
    component: ProjectSurveys,
    exact: true,
    path: path.Projects.Surveys,
  },
  {
    component: ProjectSurveySettings,
    exact: true,
    path: path.Projects.SurveySettings,
  },
  {
    component: GroupProjectCloningContainer,
    path: path.Projects.Clone,
  },
  {
    component: SurveyPreviewComplete,
    path: path.Projects.PreviewComplete,
  },
  {
    component: ProjectTestDriveContainer,
    path: path.Projects.Preview,
  },
  {
    component: SurveyVersionPreviewContainer,
    path: path.Projects.SurveyPreview,
  },
  {
    component: FileNotFound,
    exact: true,
    path: path.Projects.FileNotFound,
  },
  {
    component: ProjectFilePreview,
    exact: true,
    path: [
      path.Projects.FileTabs,
      path.Projects.File,
    ],
  },
  {
    component: EntitiesOnboarding,
    exact: true,
    path: path.Projects.EntitiesOnboarding,
  },
  {
    component: ProjectTagsOnboardingContainer,
    exact: true,
    path: path.Projects.TagsOnboarding,
  },
  {
    component: GroupProject,
    path: [
      path.Projects.ProjectTabs,
      path.Projects.Project,
    ],
  },
];

const consultant: Routing.Route[] = [
  {
    component: Consultant.Projects,
    exact: true,
    path: path.Projects.Root,
  },
  {
    component: Consultant.Project,
    exact: true,
    path: path.Projects.Project,
  },
];

const datasteward: Routing.Route[] = [
  {
    component: CallTranscript,
    exact: true,
    path: path.Projects.Cleanup,
  },
];

const validator = (state: Store.State) => {
  return utils.hasDataStewardRole(state.user)
    ? datasteward
    : utils.hasClientRole(state.user)
      ? client
      : consultant;
};

export const router = StateRouter(validator, {
  auth: RouteAuth.Authenticated,
  path: path.Projects.Root,
});