import { forwardRef, useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useSurveyBuilderState, useIsEditingSurvey } from '@containers/SurveyBuilder';
import { useSurveyBuilderOptions } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { Header, Subtitle, Title } from '@screens/ProjectCreation/Header';
import * as $api from '@api';
import Toast from '@/components/Toast';
import { SurveyMetadata } from '@/types';
import { ProjectSurveyBuilder, useProjectSurveyBuilderTabs, DraftNotification, DraftGuard } from '@/components/SurveyBuilder';
import { useProjectSurveyStepperContext, useProjectFormContext } from './context';
import { BackButton, Footer, LoadingNextButton, DiscardDraftButton } from './Footer';
import styles from './style.css';

const ProjectSurvey = forwardRef((_, ref) => {
  const { maxQuestions } = useSurveyBuilderOptions();
  const tabs = useProjectSurveyBuilderTabs();

  const stepperActions = useProjectSurveyStepperContext();

  const [state, dispatchSurvey, clearSurvey] = useSurveyBuilderState();
  const isEditing = useIsEditingSurvey();
  const [form, dispatchProjectForm] = useProjectFormContext();

  const { mutate: goNext, isLoading: isSaving } = useMutation(['save-project-survey'], () => {
    return $api.surveys.upsertSurvey({
      survey: state.survey,
      draftVersionId: state.draft.surveyVersionId,
    });
  }, {
    onSuccess: result => {
      dispatchProjectForm({
        surveys: [...form.surveys.filter(s => s.id != result.survey.id), result.survey],
      });
      stepperActions.goTo(0);
      clearSurvey();
    },
    onError: () => {
      Toast.error({ title: 'There was an error saving the survey' });
    },
  });

  const disabled = isEditing ||
    state.draft.status !== 'idle' ||
    !state.survey.surveyDetails?.name ||
    !state.survey.surveyDetails?.typeId ||
    !state.survey.items.length ||
    !state.survey.sections
      .every(e => !!e.name && state.survey.items.some(s => s.section.identifier === e.identifier));

  return (
    <>
      <div className={styles.section}>
        <div className={styles.wrap}>
          <Header>
            <Title className={styles.row}>Survey Questions</Title>
            <Subtitle className={styles.row}>{`Please provide up to ${maxQuestions} questions for the survey.`}</Subtitle>
          </Header>
          <div className={styles.row}>
            <DraftNotification />
          </div>
          <ProjectSurveyBuilder tabs={tabs} />
        </div>
        <Footer>
          <BackButton onClick={stepperActions.back} />
          <div>
            <DiscardDraftButton />
            <LoadingNextButton
              implicitDisable={false}
              loading={isSaving}
              disabled={isSaving || disabled}
              onClick={() => goNext()} />
          </div>
        </Footer>
      </div>
      <DraftGuard />
    </>
  );
});

export { ProjectSurvey };
export default ProjectSurvey;